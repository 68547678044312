import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import styled from 'styled-components'

const MainWrapper = styled.div`
margin: 0;
`

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Is Blockchain?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Blockchain is the main tech behind bitcoin. People who are involved in this network have their own data store that saves all the transactions they made on the network."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "How Does A Blockchain Work?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "When a digital transaction happens in a blockchain, it is grouped in a cryptographically-secured block alongside other transactions that have occurred in the same time frame. The block of transactions is verified by participants who validate and relay transaction information. Codestaff can help you create a solid blockchain."
        }
    }
}

const Blockchain = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> Blockchain Developers.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top Blockchain Developers. Top
                                    companies and start-ups choose Codestaff Blockchain professionals
                                    for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top Blockchain Developer now
                                    <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE BLOCKCHAIN DEVELOPERS' banner='/blockchain.png' bannerAlt='blockchain banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default Blockchain