import React from 'react'
import Layout from '../components/layout'
import Blockchain from '../components/categories/blockchain'
import Seo from '../components/seo'

const BlockchainPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Blockchain />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Blockchain Freelancers | Codestaff'
  const description =
    'Hire the best Blockchain freelancers at Codestaff. Get the top 1% of Blockchain professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default BlockchainPage
